import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';
import lazySizes from 'lazysizes';
import '../vendors/ls.unveilhooks.min.js';

(function() {
  'use strict';

  window.lazySizes = window.lazySizes || {};
  window.lazySizes.cfg.lazyClass = 'js-lazyload';
  window.lazySizes.cfg.preloadClass = 'u-lazyload-preload';
  window.lazySizes.cfg.loadingClass = 'u-lazyload-loading';
  window.lazySizes.cfg.loadedClass = 'u-lazyload-loaded';

})();
