/**
 * FEATURE
 * Name: Subscribe
 ********************************* */

import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */
  var url   = $('meta[name=_my_subscribe_url]').attr('content'),
      token = $('meta[name=_my_subscribe_token]').attr('content');


  /**
   * CLICK
   *********************** */
  $('[data-feature="subscribe"]').on('click', function(event) {
    event.preventDefault();

    var $this = $(this),
        id    = $this.data('id');

    if(id && url && token) {
      var real_url = url.replace('XXX', '');

      $.ajax({
        type: 'POST',
        url: real_url + id,
        data: 't=' + token,
        success: function(data, status) {
          $('[data-feature="subscribe"][data-id="' + id + '"]').attr('data-state', data.state);
        },
        error: function(data, status, error) {
          alert('Erreur.');
        }
      });
    }
    else {
      
      if($('.js-overlay').length > 0) {
        $('.js-overlay').addClass('is-active');
      }
    }
  });

})();
