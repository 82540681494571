import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';


(function() {
  'use strict';


  /**
   * REPLAY
   *********************** */

  if($('.js-video').length > 0) {


    /**
     * VARIABLES
     *********************** */

    var $video  = $('.js-video'),
        $poster = $('.js-video-play'),
        source  = $video.data('source'),
        p       = $video.data('p'),
        s       = $video.data('s'),
        $iframe = document.querySelector('.js-video-iframe');


    /**
     * DIGITEKA
     *********************** */
      
    if(source == "digiteka") {
      var player = $iframe;

      window.addEventListener("message", receiveMessage, false);

      function receiveMessage(e) {
        var data = e.data;

        if(typeof data == "string" && data.startsWith("event=")) {
          var dataJSON = JSON.parse('{"' + decodeURI(e.data).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

          /**
           * INIT
           *********************** */

          if(dataJSON.event == "ready" && s != 0 && p != 100) {
            player.contentWindow.postMessage('setCurrentTime='+s, '*');
          }
        }
      };
    }


    /**
     * VIMEO
     *********************** */

    else if(source == "vimeo") {
      var player = new Vimeo.Player($iframe);

      $('.js-video-play').on('click', function(event) {

        /**
         * INIT
         *********************** */

        $video.addClass('is-visible');
        $poster.remove();

        if(s != 0 && p != 100) {
          player.setCurrentTime(s);
        }

        /**
         * PLAY
         *********************** */
        player.play();

      });
    }

  }



  /**
   * LIVE
   *********************** */

  $('.js-live-play').on('click', function(event) {


    /**
     * VARIABLES
     *********************** */
    var $poster = $(this),
        $video  = $poster.parent().find('.js-live-wrapper video');


    /**
     * ACIONS
     *********************** */
    $poster.addClass('is-hidden');
    $video.get(0).play();

  });

})();
