/**
 * FEATURE
 * Name: PROGRESS
 ********************************* */

import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';


  if($('.js-video-iframe').length > 0) {


    /**
     * VARIABLES
     *********************** */
    var url      = $('meta[name=_my_progress_url]').attr('content'),
        token    = $('meta[name=_my_progress_token]').attr('content'),
        $video   = $('.js-video'),
        source   = $video.data('source'),
        $iframe  = document.querySelector('.js-video-iframe'),
        id       = $video.data('id'),
        loop;


    /**
     * FUNCTIONS
     *********************** */
    function sendProgress(url, token, seconds, percent) {
      var data = 't=' + token + '&seconds=' + seconds + '&percent=' + percent;

      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        success: function(data, status) {

        },
        error: function(data, status, error) {

        }
      });
    }


    /**
     * ACTIONS
     *********************** */
    if(id && url && token) {
      var real_url = url.replace('XXX', id);


      /**
       * ----------/!\----------
       * ------ DIGITEKA -------
       * ----------/!\----------
       *********************** */

      if(source == "digiteka") {
        var startSeconds = 0,
            tmpDuration  = 0,
            tmpTime      = 0;

        window.addEventListener("message", receiveMessage, false);

        function receiveMessage(e) {
          var data = e.data;

          if(typeof data == "string" && data.startsWith("event=")) {
            var dataJSON = JSON.parse('{"' + decodeURI(e.data).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

            if(dataJSON.event == "getCurrentTime") {
              tmpTime = dataJSON.time;
            }

            if(dataJSON.event == "getDuration" && dataJSON.duration) {
              tmpDuration = dataJSON.duration;
            }
          }
        };

        var player = $iframe;


        player.contentWindow.postMessage('getDuration', '*');


        clearInterval(loop);
        loop = setInterval(function() {
          var duration = 0,
              seconds = 0,
              percent = 0;

          player.contentWindow.postMessage('getDuration', '*');
          player.contentWindow.postMessage('getCurrentTime', '*');

          if(tmpDuration > 0 && tmpTime > 0) {
            duration = Math.round(tmpDuration);
            seconds  = Math.round(tmpTime);
            percent  = Math.round(seconds/duration * 100);

            // console.log("duration: " + duration);
            // console.log("seconds: " + seconds);
            // console.log("percent: " + percent);

            sendProgress(real_url, token, seconds, percent);
          }

        }, 5000);


        /**
         * INIT
         *********************** */
        // Code here...


        /**
         * PLAY
         *********************** */
        // Code here...


        /**
         * PAUSE / END
         *********************** */
        // Code here...


        /**
         * OTHERS...
         *********************** */
        // Code here...

      }


      /**
       * ----------/!\----------
       * -------- VIMEO --------
       * ----------/!\----------
       *********************** */

      else if(source == "vimeo") {
        var player = new Vimeo.Player($iframe);


        /**
         * INIT
         *********************** */
        // Loaded
        // First event fired when the player is ready
        // player.on('loaded', function(data) {
        //   console.log('loaded', data);
        // });


        /**
         * PLAY
         *********************** */
        player.on('play', function(data) {

          clearInterval(loop);
          loop = setInterval(function() {
            var duration = 0,
                seconds = 0,
                percent = 0;

            player.getDuration().then(function(duration) {
              duration = Math.round(duration);

              player.getCurrentTime().then(function(time) {
                seconds  = Math.round(time);
                percent  = Math.round(time/duration * 100);

                sendProgress(real_url, token, seconds, percent);
              });
            });

          }, 10000);
        });

        // Progress
        // Gives video loading informations
        // player.on('progress', function(data) {
        //   console.log('progress', data);
        // });


        /**
         * PAUSE / END
         *********************** */
        player.on('pause', function(data) {
          clearInterval(loop);

          var seconds  = Math.round(data.seconds),
              percent  = Math.round(data.percent * 100);

          sendProgress(real_url, token, seconds, percent);
        });

        // player.on('ended', function(data) {
        //   console.log('ended', data);

        //   clearInterval(loop);
        // });


        /**
         * OTHERS...
         *********************** */
        // Seeked
        // Fires when the user change marker position
        // player.on('seeked', function(data) {
        //   console.log('seeked', data);
        // });


        // Timeupdate
        // Fires every 250ms to give time position
        // player.on('timeupdate', function(data) {
        //   console.log('timeupdate', data);
        // });

      }
    }


    /**
     * MISC
     *********************** */
    if($('.js-share-button[data-type="code"]').length > 0) {

      if(source == "digiteka") {
        $('.js-share-button[data-type="code"]').attr('data-code', $('.js-video-iframe').get(0).outerHTML);
      }
      else if(source == "vimeo") {
        var player = new Vimeo.Player($iframe);
        player.getVideoEmbedCode().then(function(code) {
          $('.js-share-button[data-type="code"]').attr('data-code', code);
        });
      }
    }

  }

})();
