import { $, $win, $doc, $html, $body } from './helpers/_jquery.utils.js';
import svg4everybody from 'svg4everybody';
import 'slick-carousel';
import 'focus-visible';

svg4everybody();


(function() {
  'use strict';

  $doc.ready(function() {
    $body.addClass('is-ready');
  });

  $win.on('load', function() {
    $body.addClass('is-loaded');
  });

})();

import './modules/_banner.js';
import './modules/_browser.js';
import './modules/_dropdown.js';
import './modules/_flexible.js';
import './modules/_lazyload.js';
import './modules/_overlay.js';
import './modules/_play.js';
import './modules/_share.js';
import './modules/_slider.js';

import './features/_alert.js';
import './features/_favourite.js';
import './features/_progress.js';
import './features/_subscribe.js';
