/**
 * FEATURE
 * Name: Favourite
 ********************************* */

import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */
  var url   = $('meta[name=_my_favorite_url]').attr('content'),
      token = $('meta[name=_my_favorite_token]').attr('content');


  /**
   * FUNCTION
   *********************** */
  function toggleFavourite($element) {
    var $this = $element,
        id    = $this.data('id');

    if(id && url && token) {
      var real_url = url.replace('XXX', '');

      $.ajax({
        type: 'POST',
        url: real_url + id,
        data: 't=' + token,
        success: function(data, status) {
          $('[data-feature="favourite"][data-id="' + id + '"]').attr('data-state', data.state);
        },
        error: function(data, status, error) {
          alert('Erreur.');
        }
      });
    }
    else {
      
      if($('.js-overlay').length > 0) {
        $('.js-overlay').addClass('is-active');
      }
    }
  };


  /**
   * INIT
   *********************** */
  $('[data-feature="favourite"]').bind('click', function(event) {
    event.preventDefault();
    toggleFavourite($(this));
  });


  /**
   * RE-INIT
   *********************** */
  $('.js-slider').on('breakpoint', function(event, slick, breakpoint) {
    $('[data-feature="favourite"]').unbind('click');

    $('[data-feature="favourite"]').bind('click', function(event) {
      event.preventDefault();
      toggleFavourite($(this));
    });
  });

})();
