import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';


  /**
   * VARIABLES
   *********************** */
  var $temp = $("<input>"),
      value;


  /**
   * FUNCTIONS
   *********************** */
  function showValue($button, type, value) {
    var $share = $button.parents('.js-share'),
        $label = $button.find('.js-share-label'),
        markup = value;

    // Copy
    $body.append($temp);
    $temp.val(value).select();
    document.execCommand("copy");
    $temp.remove();

    // Edit button label
    markup = "<input class='c-share__value' type='text' value='" + value + "' />";

    $label.html(markup);

    // Activate & animate
    $share.addClass('is-active');
    $share.addClass('is-animating');

    setTimeout(function(){
      $share.removeClass('is-animating');
    }, 1000);
  }


  /**
   * ACTIONS
   *********************** */
  $('.js-share-button').on('click', function(event) {
    event.preventDefault();

    var $button = $(this),
        type    = $button.data('type');

    if(type) {

      if(type == 'link' && $button.data('url')) {
        value = $button.data('url');
        showValue($button, type, value);
      }
      else if(type == 'code' && $button.data('code')) {
        value = $button.data('code');
        showValue($button, type, value);
      }
      else {
        alert('Erreur.');
      }
    }

    else {
      alert('Erreur.');
    }

  });

})();
