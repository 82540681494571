import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';
import 'slick-carousel';


(function() {
  'use strict';


  $('.js-slider-pictures').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 600,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });


  $('.js-slider-featured').slick({
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
  });

  $('.js-slider-videos').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.js-slider-recent').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.js-slider-flow').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.js-slider-highlights').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.js-slider-days').slick({
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    speed: 500,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  });

  $('.js-slider-programs').slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 2,
    speed: 500,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  $('.js-slider-team').slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 300,
    prevArrow: '<button class="v-slider__arrow v-slider__arrow--prev"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 12l1.06-1.06-4.19-4.19H12v-1.5H2.87l4.19-4.19L6 0 0 6z" fill-rule="nonzero"/></svg></button>',
    nextArrow: '<button class="v-slider__arrow v-slider__arrow--next"><svg viewBox="0 0 12 12" width="12" height="12"><path fill="#fff" d="M6 0L4.94 1.06l4.19 4.19H0v1.5h9.13l-4.19 4.19L6 12l6-6z" fill-rule="nonzero"/></svg></button>',
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 695,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 368,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });

  if($('.js-program-current').length > 0) {
    var $program = $('.js-program-current'),
        $slider  = $program.parents('.js-slider-programs'),
        index    = $program.parents('.slick-slide').data('slick-index');

    $slider.slick('slickGoTo', index);
  }

  if($('.js-day-current').length > 0) {
    var $program = $('.js-day-current'),
        $slider  = $program.parents('.js-slider-days'),
        index    = $program.parents('.slick-slide').data('slick-index'),
        max      = $slider.slick('slickGetOption', 'slidesToShow') - 1,
        position = index > max ? max : index;

    $slider.slick('slickGoTo', position, false);
  }

})();
