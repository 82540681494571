import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';


(function() {
  'use strict';

  $('.js-flexible-button').on('click', function(event) {
    var $button   = $(this),
        $flexible = $(this).parents('.js-flexible');
    
    $button.toggleClass('is-active');
    $flexible.toggleClass('is-active');
  });

})();
