import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';

  $('.js-overlay-close').on('click', function(event) {
    event.preventDefault();
    $(this).parents('.js-overlay').removeClass('is-active');
  });

})();
