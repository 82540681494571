import { $, $win, $doc, $html, $body } from '../helpers/_jquery.utils.js';

(function() {
  'use strict';

  if($('.js-banner-picture').length > 0) {

    $win.on('scroll', function(event) {
      var offset = $win.scrollTop() / 2;
      $('.js-banner-picture').css('transform', 'translate3d(0, ' + offset + 'px, 0)');
    });
  }

})();
